.port__header {
    display: flex;
    flex-direction: row;
    height: 400px;
    align-items: center;
    justify-content: center;
}

.port__header-box {
    display: flex;
    position: relative;
    width: 22rem;
    height: 19rem;
    border-radius: 50%;
    background: var(--color-bg);
    overflow: hidden;
    margin-left: 6rem;
}

.port__header-box::before {
    content: '';
    position: absolute;
    inset: -10px 50px;
    background: var(--gradient-bg);
    transition: 0.5s;
    animation: rotate 8s linear infinite;
}

.port__header-box:hover::before {
    inset: -20px 0px;
}

@keyframes rotate {
    
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }

}

.port__header-box::after {
    content: '';
    position: absolute;
    inset: 6px;
    background: var(--color-bg);
    border-radius: 50%;
    z-index: 1;
}

.port__header-box_content {
    position: absolute;
    inset: 20px;
    border: 6px solid var(--color-footer);
    z-index: 3;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media screen and (min-width: 2500px) and (max-width: 2750px) {
    
    .port__header {
        width: 50%;
        margin: 0 auto;
    }

    .port__header-box {
        width: 18rem;
        height: 16rem;
    }

}

@media screen and (min-width: 1701px) and (max-width: 2499px) {
    
    .port__header {
        width: 70%;
        margin: 0 auto;
    }

    .port__header-box {
        width: 18rem;
        height: 16rem;
    }

}

@media screen and (min-width: 1500px) and (max-width: 1700px) {
    
    .port__header {
        width: 80%;
        margin: 0 auto;
    }

    .port__header-box {
        width: 18rem;
        height: 16rem;
    }

}

@media screen and (max-width: 1120px) {
    
    .port__header-box {
        width: 22rem;
        height: 18rem;
        margin-left: 6rem;
    }

}

@media screen and (max-width: 830px) {

    .port__header-box {
        width: 24rem;
        height: 16rem; 
        border-radius: 50%; 
        margin-left: 3rem;
    }

}

@media screen and (max-width: 685px) {

    .port__header {
        flex-direction: column;
        height: 600px;
    }

    .port__header-box {
        width: 15rem;
        height: 15rem; 
        border-radius: 50%;
        margin-left: 2rem; 
    }

}


/* Button Styling */
.port__header-box_button-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.port__header-box_button-content button {
    padding: 1rem;
    border-radius: 1rem;
    background: var(--color-footer);
    color: var(--color-bg);
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1rem;
    border: none;
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 2px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: color linear 0.9s;
}

.port__header-box_button-content button:hover {
    background: var(--gradient-bg);
    color: black;
    font-weight: 800;
    border: solid 1px black;
}

.port__header-box_button-content button:active {
    background: var(--color-footer);
    color: var(--color-bg);
}

/* Rotating Img Graphic */
.port__header-box_content img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s;
    pointer-events: none;
}

.port__header-box:hover .port__header-box_content img {
    opacity: 0;
}

/* Description */
.port__header-description_container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    font-size: 1rem;
    font-family: var(--font-family);
}

.port__header-description_container h1,
.port__header-description_container h2,
.port__header-description_container h3 {
    font-family: var(--font-two);
    letter-spacing: 0.04rem;
}

.port__header-description_container h1 {
    font-weight: 800;
    font-size: 5rem;
}

.port__header-description_container h2 {
    font-weight: 800;
    font-size: 3rem;
}

.port__header-description_container h3 {
    font-weight: 800;
    font-size: 2rem;
    background: transparent;
}

.port__header-description_container h3 span {
    background: var(--gradient-bg);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.port__header-description_container span {
    font-weight: 900;
    font-size: 3.1rem;
    color: var(--gradient-bg);
}


@media screen and (max-width: 1120px) {
    
    .port__header-description_container {
        margin-left: 0rem;
        width: 80%;
    }

    .port__header-description_container h1 {
        font-weight: 800;
        font-size: 3.5rem;
    }
    
    .port__header-description_container h3 {
        font-weight: 800;
        font-size: 2rem;
    }
    
    .port__header-description_container h2 {
        font-weight: 800;
        font-size: 2rem;
    }

    .port__header-description_container span {
        font-size: 2.5rem;
    }

}

@media screen and (max-width: 875px) {
    

    .port__header-description_container {
        margin-left: 1rem;
        width: 80%;
    }

    .port__header-description_container h1 {
        font-weight: 800;
        font-size: 3rem;
    }
    
    .port__header-description_container h3 {
        font-weight: 800;
        font-size: 1.5rem;
    }
    
    .port__header-description_container h2 {
        font-weight: 800;
        font-size: 1.5rem;
    }

    .port__header-description_container span {
        font-size: 2rem;
    }

}

@media screen and (max-width: 735px) {
    
    .port__header-description_container {
        font-size: 0.8rem;
        width: 80%;
        margin-top: 2rem;
        margin-left: 2rem;
    }

    .port__header-description_container h2 {
        font-weight: 800;
        font-size: 1.3rem;
    }

    .port__header-description_container span {
        font-size: 1.6rem;
    }

}

@media screen and (max-width: 455px) {

    .port__header-box {
        margin-top: 3rem;
        margin-left: 0rem;
    }
    
    .port__header-description_container {
        margin-left: 0rem;
        margin-top: 3rem;
    }
    
    .port__header-description_container h3 {
        font-weight: 800;
        font-size: 1.3rem;
    }
    
    .port__header-description_container h2 {
        font-weight: 800;
        font-size: 1.2rem;
    }

    .port__header-description_container span {
        font-size: 1.4rem;
    }

}

@media screen and (max-width: 420px) {
    
    .port__header-description_container span {
        font-size: 1.2rem;
    }

}