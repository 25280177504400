.port__carousel {
    padding: 10rem;
    margin-top: 2rem;
}

.port__carousel h2,
.port__carousel p {
    font-family: var(--font-family);
}

.port__carousel h1 {
    font-family: var(--font-two);
    font-size: 3rem;
    width: 100%;
    text-align: center;
}

.port__carousel-box {
    height: 350px;
    border-radius: 10px;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
    background-image: url('../../assets/gpt3.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.port__carousel-box h1,
.port__carousel-box h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9381FF;
}

.port__carousel-box-2,
.port__carousel-box-3,
.port__carousel-box-4 {
    display: flex;
    text-align: center;
    padding: 2rem;
    height: 350px;
    border-radius: 10px;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
}

.port__carousel-box-3 {
    background-image: url(../../assets/quacky-screenshot.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.port__carousel-box-4 {
    background-image: url(../../assets/eastern-electrical.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}
.port__carousel-box_btn-4,
.port__carousel-box_btn-3 {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
}

.port__carousel-box_btn button,
.port__carousel-box_btn-3 button,
.port__carousel-box_btn-4 button {
    font-family: var(--font-two);
    font-weight: 600;
    font-size: 0.9rem;

    width: 100%;
    padding: 0.7rem;
    margin-top: 12rem;

    border-radius: 1rem;
    background: var(--color-bg);
    color: var(--color-footer);
    border: none;
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 2px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: all ease-in-out 0.5s;
}

.port__carousel-box_btn-3 button {
    margin-top: 15rem;

    background: #000;
    color: var(--color-bg);
}

.port__carousel-box_btn-4 button {
    margin-top: 15rem;

    background: #000;
    color: var(--color-bg);
}

.port__carousel-box_btn button:hover,
.port__carousel-box_btn-3 button:hover,
.port__carousel-box_btn-4 button:hover {
    background: var(--gradient-bg);
    color: black;
    border: solid 1px black;
}

.slick-slide div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overflow-content {
    overflow-y: scroll;
}

.slick-slide img {
    display: flex;
}

body .slick-next:before, body .slick-prev:before {
    color: black;
    opacity: 0.4;
}

@media screen and (min-width: 2400px) and (max-width: 3400px) {
    
    .port__carousel {
        padding: 10rem 40rem;
        margin-top: 4rem;
    }

}

@media screen and (min-width: 1800px) and (max-width: 2400px) {
    
    .port__carousel {
        padding: 10rem 30rem;
        margin-top: 4rem;
    }

}


@media screen and (min-width: 1000px) and (max-width: 1800px) {
    
    .port__carousel {
        padding: 10rem 15rem;
        margin-top: 4rem;
    }

}

@media screen and (max-width: 1100px) {
    
    .port__carousel {
        padding: 4rem;
        margin-top: 4rem;
    }

}

@media screen and (max-width: 800px) {
    
    .port__carousel h1 {
        font-size: 2rem;
    }

}

@media screen and (max-width: 450px) {

    .port__carousel h1 {
        font-size: 2rem;
    }

    .port__carousel {
        padding: 2rem;
        margin-top: 4rem;
    }

}

/* Styling for Case Study Button */
.port__carousel-case {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}