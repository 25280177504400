* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg);
    min-height: 100vh;
}

a {
    color: unset;
    text-decoration: none;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
            transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
            transform: scale(1);
    }
}

@keyframes scale-up-center {
    0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
            transform: scale(1);
    }
}

/* Custom Scrollbar */
::-webkit-scrollbar {
    width: 0.5rem;
}

::-webkit-scrollbar-track {
    background-color: var(--color-bg);
    margin-block: 0.5rem;
}

::-webkit-scrollbar-thumb {
    background: lightgrey;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--gradient-bg);
}