.port__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
}

.port__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.port__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.port__navbar-links_container p {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 25px;
    text-transform: capitalize;
    color: var(--color-footer);

    margin: 0 1rem;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
}

.port__navbar-links_container a:hover {
    transition: all ease-in-out 0.3s;
}

.port__navbar-links_container p:hover,
.port__navbar-links_container a:hover {
    color: var(--color-footer);
    font-weight: 800;
    text-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
}

.port__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.port__navbar-menu svg {
    cursor: pointer;
    fill: #000;
}

.port__navbar-menu_container {
    z-index: 4;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.9);
}

.port__navbar-menu_container p {
    margin: 1rem 0;
    color: var(--color-bg);
    font-family: var(--font-family);
}

.port__navbar-menu_container p:hover {
    font-weight: 600;
}

@media screen and (max-width: 1050px) {
    
    .port__navbar-links_container {
        display: none;
    }

    .port__navbar-menu {
        display: flex;
    }

}

@media screen and (max-width: 700px) {
    
    .port__navbar {
        padding: 2rem 4rem;
    }

}

@media screen and (max-width: 550px) {
    
    .port__navbar {
        padding: 2rem;
    }
    
}