@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Reddit+Mono:wght@200..900&display=swap');

:root {
  --font-family: 'Reddit Mono', sans-serif;
  --font-two: 'Oswald', sans-serif;

  --gradient-text: linear-gradient(-45deg, #9381FF, #FF69B4);
  --gradient-bg: linear-gradient(-45deg, #9381FF, #FF69B4);
  
  --color-bg: #F5F5F5;
  --color-footer : #000;
  --color-about: #496349;
  --color-text: #000;
  --color-subtext: #86B886;
}