.port__quacky-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin: 2rem;
}

.port__quacky-container h1,
.port__quacky-container h2 {
    font-family: var(--font-family);

    margin: 3rem;
}

.port__quacky-container h2 {
    font-size: 1.5rem;

    width: 100%;
}

.port__quacky-container a,
.port__quacky-container p {
    font-family: var(--font-two);
}

.port__quacky-container p {
    width: 80%;

    text-align: center;
    line-height: 2.5rem;
    letter-spacing: 0.05rem;

    font-size: 1.4rem;
}

.port__quacky-container a {
    cursor: pointer;

    transition: all ease-in-out 0.3s;
}

.port__quacky-container_links {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 250px;
    margin: 1rem;
}

.port__quacky-container a:hover {
    text-decoration: underline;
}

.port__quacky-container .fab {
    margin-left: 0.5rem;
}

.port__quacky-container img {
    width: 70%;
    margin: 2rem;

    border-radius: 10px;
}

.port__quacky-container span {
    font-weight: bold;
}

@media screen and (max-width: 635px) {
    
    .port__quacky-container h1 {
        font-size: 1.5rem;
    }

}

@media screen and (max-width: 450px) {
    
    .port__quacky-container h1 {
        font-size: 1.1rem;
    }

    .port__quacky-container h2 {
        font-size: 1.05rem;
    }

    .port__quacky-container p {
        font-size: 1rem;
    }

}